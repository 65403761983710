import { Component } from '@angular/core';
import { IonRouterOutlet, IonSpinner, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { GetCareService } from './get-care.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-get-care-base',
  standalone: true,
  template: '<ion-router-outlet></ion-router-outlet>',
  imports: [IonRouterOutlet, IonSpinner],
  providers: [GetCareService],
})
export class GetCareBaseComponent implements ViewWillEnter, ViewWillLeave {
  constructor(
    private readonly getCareService: GetCareService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  async ionViewWillEnter(): Promise<void> {
    this.getCareService.initService();
    this.getCareService.setType(this.activatedRoute.snapshot.params['type']);
  }

  ionViewWillLeave(): void {
    this.getCareService.clear();
  }
}
